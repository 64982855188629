<template>
  <div class="sub-cont">
    <div class="complete-box">
      <img
        class="payment-fail"
        src="~assets/image/ico/ico-withdrawal-fail.png"
      />
      <p>결제가 취소되었습니다. <br />처음부터 다시 진행해주세요. <br /></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sec: 3,
      intervalId: null,
    }
  },
  created() {
    if (this.intervalId) this.intervalId = null
    this.intervalId = setInterval(() => {
      this.sec--
      if (this.sec === 0) {
        if (window.opener) {
          window.close()
        } else {
          if (this.$route.query.paymentPath === '1') {
            document.location.href = '/regist-job'
          } else {
            document.location.href = '/mypage/calc-history'
          }
        }
      }
    }, 1000)
  },
}
</script>

<style scoped>
.complete-box {
  height: 515px;
}
.payment-fail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
}
</style>
